import apiClient from '../ApiClient';
import { TABS_URL } from '../../constants/api';

import { ContactTabItem } from '../../types/organization/tabs/contactType';
import { InformationTabType } from '../../types/organization/tabs/informationType';

export const fetchTabContent = async (
  uid: string | undefined,
  tabName: string,
  tabParams?: {},
): Promise<InformationTabType | ContactTabItem[]> => {
  return await apiClient.runRequestAsync({
    url: TABS_URL,
    data: {
      sTabName: tabName,
      sClientUid: uid,
      ...tabParams,
      // iDebugSleepSec: 0.5,
    },
  });
};
