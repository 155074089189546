import { CurrentOrganizationResponse } from '../../types/organization/currentOrganization/currentOrganizationTypes';
import apiClient from '../ApiClient';
import { CLIENT_URL } from '../../constants/api';

export const fetchCurrentOrganizationData = async (
  uid: string | undefined,
): Promise<CurrentOrganizationResponse> => {
  return await apiClient.runRequestAsync({
    url: CLIENT_URL,
    data: {
      sPresetName: 'clientTabs',
      rFilterData: {
        sElementUid: uid,
      },
      // iDebugSleepSec: 555,
    },
  });
};
