import { makeAutoObservable } from 'mobx';

import {
  CurrentOrganizationData,
  CurrentOrganizationResponse,
  CurrentTabs,
  ECurrentTabKey,
} from '../../types/organization/currentOrganization/currentOrganizationTypes';

import { fetchCurrentOrganizationData } from '../../api/currentOrganization/fetchCurrentOrganizationData';
import { BsInfoSquare } from 'react-icons/bs';
import {
  ContactsTab,
  FinanceTab,
  HoldingTab,
  InformationTab,
} from '../../components/lazy';
import { PiTreeStructure } from 'react-icons/pi';
import { TiContacts } from 'react-icons/ti';
import { MdEventNote } from 'react-icons/md';
import { GrMoney } from 'react-icons/gr';
import { FcMoneyTransfer } from 'react-icons/fc';

export class CurrentOrganization {
  currentOrganization: CurrentOrganizationData | null = null;
  isLoading: boolean = false;
  isOrganizationEdit: boolean = false;
  currentTabs: CurrentTabs[] = [];
  currentTab: ECurrentTabKey = ECurrentTabKey.main;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentOrganization(data: any | null) {
    this.currentOrganization = data ? data.aData : null;
  }

  setEditOrganization(value: boolean) {
    this.isOrganizationEdit = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setCurrentTab(value: ECurrentTabKey) {
    this.currentTab = value;
  }

  pushCurrentTab(value: CurrentTabs) {
    this.currentTabs.push(value);
  }

  resetCurrentTabs() {
    this.currentTabs.length = 0;
  }

  getCurrentTabs(response: CurrentOrganizationResponse) {
    this.resetCurrentTabs();

    this.pushCurrentTab({
      eventKey: ECurrentTabKey.main,
      title: 'Информация',
      icon: <BsInfoSquare size={16} />,
      component: <InformationTab />,
    });

    if (response.aData?.rAccess.bTabHolding) {
      this.pushCurrentTab({
        eventKey: ECurrentTabKey.holding,
        title: 'Структура холдинга',
        icon: <PiTreeStructure size={16} />,
        component: <HoldingTab />,
      });
    }
    if (response.aData?.rAccess.bTabContacts) {
      this.pushCurrentTab({
        eventKey: ECurrentTabKey.contacts,
        title: 'Контакты',
        icon: <TiContacts size={16} />,
        component: <ContactsTab />,
        length: response.aData.rInfo.iCntAllContacts,
      });
    }
    if (response.aData?.rAccess.bTabHandlers) {
      this.pushCurrentTab({
        eventKey: ECurrentTabKey.events,
        title: 'События',
        icon: <MdEventNote size={16} />,
        component: <div>События</div>,
      });
    }
    if (response.aData?.rAccess.bTabFinance) {
      this.pushCurrentTab({
        eventKey: ECurrentTabKey.finance,
        title: 'Финансы',
        icon: <GrMoney size={16} />,
        component: <FinanceTab />,
      });
    }
    if (response.aData?.rAccess.bTabZp) {
      this.pushCurrentTab({
        eventKey: ECurrentTabKey.salary,
        title: 'ЗП модуль',
        icon: <FcMoneyTransfer size={16} />,
        component: <div>ЗП модуль</div>,
      });
    }
  }

  async fetchCurrentOrganization(uid: string | undefined) {
    this.setIsLoading(true);
    const response = await fetchCurrentOrganizationData(uid);
    this.setCurrentOrganization(response);
    this.getCurrentTabs(response);
    this.setIsLoading(false);
  }
}

export const currentOrganizationStore = new CurrentOrganization();
