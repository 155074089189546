import React from 'react';

export type CurrentOrganizationResponse = {
  iAllClients: number;
  aData: CurrentOrganizationData;
  hasError?: boolean;
};

export type CurrentOrganizationData = {
  rAccess: {
    bBtnEditCard: boolean;
    bBtnSetMeAsMainManager: boolean;
    bHasUniqueSchemas: boolean;
    bTabContacts: boolean;
    bTabFinance: boolean;
    bTabHandlers: boolean;
    bTabHolding: boolean;
    bTabZp: boolean;
    iTotalBlockType: number;
  };
  rInfo: {
    iCntAllContacts: number;
    sClientInn: string;
    sClientKpp: string;
    sFullTitle: string;
    sUidElement: string;
  };
  hasError?: boolean;
};

export enum ECurrentTabKey {
  'main',
  'holding',
  'contacts',
  'events',
  'finance',
  'salary',
}
export type CardNavProps = {
  handler: (eventKey: ECurrentTabKey) => void;
};

export type CurrentTabs = {
  eventKey: ECurrentTabKey;
  title: string;
  icon: React.ReactNode;
  component: React.ReactNode;
  length?: number;
};
