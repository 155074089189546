import { makeAutoObservable } from 'mobx';
import {
  ContactTabItem,
  IContactInitialState,
} from '../../types/organization/tabs/contactType';
import { fetchTabContent } from '../../api/tabData/fetchTabContent';

export class ContactTabStore {
  contactTabData: ContactTabItem[] | null = null;
  isContactLoading: boolean = false;
  contactInitialState: IContactInitialState[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  setContactTabData(data: ContactTabItem[]) {
    this.contactTabData = data;
  }

  setIsContactLoading(value: boolean) {
    this.isContactLoading = value;
  }

  private setContactInitialState(data: ContactTabItem[]) {
    this.contactInitialState = data.map((contactData) => ({
      c_position: contactData.c_position,
      birthday: contactData.birthday,
      firstName: contactData.name.split(' ')[0],
      secondName: contactData.name.split(' ')[1],
      lastName: contactData.name.split(' ')[2],
      chosen: false,
      selected: false,
      phones: contactData.phones
        ? contactData.phones?.map((phone) => ({
            ...phone,
            chosen: false,
            selected: false,
            phone: `${phone.code}${phone.phone}`,
          }))
        : [],
      emails: contactData.emails
        ? contactData.emails.map((email) => ({
            ...email,
            chosen: false,
            selected: false,
          }))
        : [],
    }));
  }

  async fetchContactTabData(uid: string | undefined) {
    this.setIsContactLoading(true);
    const response = await fetchTabContent(uid, 'contacts', {
      bIncudeVcards: true,
    });
    this.setContactTabData(response as ContactTabItem[]);
    this.setContactInitialState(response as ContactTabItem[]);
    this.setIsContactLoading(false);
  }
}

export const contactTabStore = new ContactTabStore();
