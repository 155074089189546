import { makeAutoObservable } from 'mobx';

import {
  CategorySalesType,
  ClientManagersType,
} from '../../types/filter/filterTypes';

import {
  fetchFilterData,
  filterDataPresets,
} from '../../api/filter/fetchFilterData';

export type SelectedList = {
  label: string;
  items: {
    label: string;
    value: number;
  }[];
};

type Category = { value: number; label: string };

export class ChangeManagerStore {
  selectedManagerList: any[] = [];
  managerList: SelectedList[] = [];
  disabledManagerList: number[] = [];
  categoryList: Category[] = [];
  disabledCategoryList: number[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  setManagerList(value: ClientManagersType) {
    this.managerList = Object.values(value || {}).flatMap((el) => ({
      label: el.sDepartmentTitle,
      items: Object.values(el.aItems).flatMap((items) => ({
        label: items.sFullName,
        value: items.iManagerId,
      })),
    }));
  }

  setCategoryList(value: CategorySalesType) {
    this.categoryList = Object.values(value || {}).flatMap((el) => ({
      label: el.sFullTitle,
      value: el.iIdElement,
    }));
  }
  setSelectedManager(value: any[]) {
    this.selectedManagerList = value;
  }
  setDisabledManagerList(value: number[]) {
    this.disabledManagerList = value;
  }

  setDisabledCategoryList(value: number[]) {
    this.disabledCategoryList = value;
  }

  async fetchChangeManagerData() {
    const response = await fetchFilterData(filterDataPresets.CHANGE_MANAGER);
    this.setManagerList(response.aClientManagers);
    this.setCategoryList(response.aCategorySalesList);
  }
}

export const changeManagerStore = new ChangeManagerStore();
