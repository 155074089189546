import React, { createContext, useContext } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  CurrentOrganization,
  currentOrganizationStore,
} from './currentOrganization/CurrentOrganizationStore';
import {
  InformationTabStore,
  informationTabStore,
} from './tabStore/InformationTabStore';
import {
  ChangeManagerStore,
  changeManagerStore,
} from './changeManager/ChangeManagerStore';
import { ContactTabStore, contactTabStore } from './tabStore/ContactTabStore';

export interface IContext {
  curStore: CurrentOrganization;
  infoStore: InformationTabStore;
  contactStore: ContactTabStore;
  changeManagerStore: ChangeManagerStore;
}

const Context = createContext<IContext>({
  curStore: currentOrganizationStore,
  infoStore: informationTabStore,
  contactStore: contactTabStore,
  changeManagerStore: changeManagerStore,
});

export const CurrentOrganizationProvider = observer(
  ({ children }: { children: React.ReactNode }) => {
    const store = useLocalObservable(() => ({
      curStore: currentOrganizationStore,
      infoStore: informationTabStore,
      contactStore: contactTabStore,
      changeManagerStore: changeManagerStore,
    }));

    return <Context.Provider value={store}>{children}</Context.Provider>;
  },
);

export const ModalOrganizationProvider = observer(
  ({ children }: { children: React.ReactNode }) => {
    const changeStore = new ChangeManagerStore();
    const store: any = useLocalObservable(() => ({
      curStore: new CurrentOrganization(),
      infoStore: new InformationTabStore(changeStore),
      contactStore: new ContactTabStore(),
      changeManagerStore: changeStore,
    }));

    return <Context.Provider value={store}>{children}</Context.Provider>;
  },
);

export const useOrganizationCardContext = () => {
  return useContext(Context);
};
