import { makeAutoObservable } from 'mobx';
import {
  IDataInitialState,
  IGeneralInitialState,
  InformationTabType,
} from '../../types/organization/tabs/informationType';
import { fetchTabContent } from '../../api/tabData/fetchTabContent';
import {
  ChangeManagerStore,
  changeManagerStore,
} from '../changeManager/ChangeManagerStore';
import { fetchOrganizationListData } from '../../api/organizationList/fetchOrganizationListData';
import { OrganizationListData } from '../../types/organization/organizationList/organizationListTypes';

export class InformationTabStore {
  informationTabData: null | InformationTabType = null;
  changeManagerService: ChangeManagerStore;
  isLoading: boolean = false;
  generalInitialState: IGeneralInitialState | null = null;
  dataInitialState: IDataInitialState | null = null;
  addressInitialState: any = null;
  technicInitialState: any = null;
  holdingIsLoading: boolean = false;

  constructor(changeManagerService: ChangeManagerStore) {
    makeAutoObservable(this);
    this.changeManagerService = changeManagerService;
  }

  setTabData(value: InformationTabType | null) {
    this.informationTabData = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setHoldingIsLoading(value: boolean) {
    this.holdingIsLoading = value;
  }

  async setGeneralInitialState(data: InformationTabType) {
    this.setHoldingIsLoading(true);
    const currentHolding = data.id_parent
      ? await fetchOrganizationListData(1, {
          companyIdList: `${data.id_parent}`,
        })
      : null;

    this.setGeneralInitialStateData(data, currentHolding);
    this.setHoldingIsLoading(false);
  }

  private setGeneralInitialStateData(
    data: InformationTabType,
    currentHolding: OrganizationListData | null,
  ) {
    this.generalInitialState = {
      clientTypes: data?.company_type_id,
      clientStatus: data?.company_status_id,
      sellersList: data?.seller_id,
      categoryOptions: data?.cut_abc_id,
      importantOptions: data?.b_client_vip ? 1 : 0,
      holding:
        data.id_parent && currentHolding
          ? Object.values(currentHolding.aData)
          : null,
      activityTypes: [],
      inn: data?.inn,
      kpp: data?.kpp,
      duplicatedArray: null,
    };
  }

  resetGeneralInitialState() {
    this.generalInitialState = null;
  }

  setDataInitialState(data: InformationTabType) {
    this.dataInitialState = {
      landSize: data?.company_area,
      organizationLink: data?.site_url,
      activityTypes: data?.json_activities?.map((el) => el.id_activity),
      organizationComment: data?.description,
      // foundationDate: data?.date_birthday ? new Date(data.date_birthday) : null,
      foundationDate: data?.date_birthday ? data?.date_birthday : null,
      groupList: data?.json_groups?.map((el) => el.id_group),
      inn: data?.inn,
      kpp: data?.kpp,
      duplicatedArray: null,
    };
  }

  setAddressInitialState(data: InformationTabType) {
    this.addressInitialState = {
      areas: { areasValue: data?.id_obl, code: data?.region_phone_code }, // Нужен ли код вообще?
      // areas: data?.id_obl,
      district: data?.id_region,
      organizationAddresses: data?.json_addresses?.map((el) => ({
        ...el,
        object_type: el.object_type_id,
        chosen: false,
        selected: false,
      })),
    };
  }

  setTechnicInitialState(data: InformationTabType) {
    this.technicInitialState = data?.json_techniks?.reduce((acc, curr) => {
      // @ts-ignore
      acc[curr.uid as keyof typeof acc] = {
        technicType: curr.id_type,
        technicMark: curr.id_type === 4 ? curr.brand : curr.brand_id,
        technicModel: curr.id_type === 4 ? curr.model : curr.model_id,
        technicCount: curr.count,
        technicComment: curr.comment,
      };
      return acc;
    }, {});
  }

  async fetchInformationTabData(uid: string | undefined) {
    this.setIsLoading(true);
    const response = await fetchTabContent(uid, 'main');
    this.setTabData(response as unknown as InformationTabType);
    this.setIsLoading(false);
    this.setDataInitialState(response as unknown as InformationTabType);
    this.setAddressInitialState(response as unknown as InformationTabType);
    this.setTechnicInitialState(response as unknown as InformationTabType);

    if (this.informationTabData) {
      const mainManager = this.informationTabData.main_manager;
      const categoryManager = this.informationTabData.json_cat_sales_managers
        ? Object.values(this.informationTabData.json_cat_sales_managers)
        : [];
      this.changeManagerService.setSelectedManager([
        mainManager,
        ...categoryManager,
      ]);
      this.changeManagerService.setDisabledManagerList([mainManager.id]);

      this.changeManagerService.setDisabledCategoryList([
        ...categoryManager.map((manager) => manager.cat_id),
      ]);
    }
  }
}

export const informationTabStore = new InformationTabStore(changeManagerStore);
// export const modalInformationTabStore = new InformationTabStore(
//   changeManagerStore,
// );
