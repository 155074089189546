import { observer } from 'mobx-react-lite';
import { callStore } from '../../../../store/callStore/CallStore';
import CloseModalButton from '../../../ui/buttons/CloseModalButton';

import styles from './ToastIncomingCall.module.scss';
import { modalStore } from '../../../../store/modal/ModalStore';
import modalsStyle from '../../../../style/interface/modalStyles/ModalsStyle.module.scss';
import { OrganizationCard } from '../../../lazy';
import { ModalOrganizationProvider } from '../../../../store/Context';
import { Dropdown } from 'react-bootstrap';

const ToastIncomingCall = observer(function ToastIncomingCall() {
  const { testCallData } = callStore;
  const closeTestHandler = (callId: string) => callStore.removeCallData(callId);

  const testClick = (uid: string, callId?: string) => {
    modalStore.openModal({
      style: modalsStyle.organization__card,
      children: (
        <ModalOrganizationProvider>
          <OrganizationCard uid={uid} isModal={true} />
        </ModalOrganizationProvider>
      ),
    });
    console.log(callId);
    // callStore.removeCallData(callId);
  };

  return (
    <>
      {testCallData && Object.values(testCallData).length > 0 && (
        <div
          className="position-fixed bottom-0 bg-white end-0 p-2 rounded-1 border border-2 border-primary d-flex flex-column gap-2 m-1"
          style={{ zIndex: 1100, overflow: 'hidden' }}
        >
          {Object.values(testCallData).map((callData, index) => (
            <div key={index} className={`${styles.toast__card} app__fs`}>
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">Входящий звонок</h6>
                <CloseModalButton
                  handler={() => closeTestHandler(callData.callId)}
                />
              </div>
              <div style={{ maxHeight: 120, overflowY: 'auto' }}>
                {/*<p className="app__fs fw-bold">Организация</p>*/}
                {callData.mainOrganizations.length > 0 && (
                  <div className="d-flex flex-wrap gap-1">
                    <p className="app__fs fw-bold">Организация:</p>
                    {callData.mainOrganizations.map((el, index) => (
                      <div key={el.id}>
                        {index === 0 && (
                          <span
                            className={styles.toast__company_title}
                            onClick={() =>
                              testClick(el.form_uid, callData.callId)
                            }
                          >
                            {el.full_name}
                          </span>
                        )}
                      </div>
                    ))}
                    {(callData.mainOrganizations.length > 1 ||
                      callData.organizations.length > 0) && (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-primary"
                          size="sm"
                          className="border-0"
                        >
                          ещё..
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {callData.mainOrganizations.length > 0 &&
                            callData.mainOrganizations.map((el) => (
                              <Dropdown.Item
                                key={el.id}
                                onClick={() =>
                                  testClick(el.form_uid, callData.callId)
                                }
                              >
                                {el.full_name}
                              </Dropdown.Item>
                            ))}
                          {callData.organizations.length > 0 &&
                            callData.organizations.map((el) => (
                              <Dropdown.Item
                                key={el.id}
                                onClick={() =>
                                  testClick(el.form_uid, callData.callId)
                                }
                              >
                                {el.full_name}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                )}
                {callData.organizations.length > 0 && (
                  <div className="d-flex flex-wrap gap-1">
                    <p className="app__fs fw-bold">
                      Контакт в других организациях:
                    </p>
                    {callData.organizations.map((el) => (
                      <p
                        key={el.id}
                        onClick={() => testClick(el.form_uid, callData.callId)}
                      >
                        {el.full_name} /
                      </p>
                    ))}
                  </div>
                )}

                {callData.companyContact.map((el) => (
                  <div key={el.id}>
                    <p>
                      <span className="fw-bold me-1">Должность:</span>
                      {el.c_position}
                    </p>
                    <p>
                      <span className="fw-bold me-1">Имя:</span>
                      {el.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
});

export default ToastIncomingCall;
